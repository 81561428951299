(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/with-global-event/assets/javascripts/with-global-event.js') >= 0) return;  svs.modules.push('/components/utils/with-global-event/assets/javascripts/with-global-event.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const globalEventState = {};
const globalEventHandler = eventType => event => globalEventState[eventType].components.forEach(handler => {
  const eventFunctionName = "onGlobal".concat(eventType.charAt(0).toUpperCase() + eventType.slice(1));
  const componentWithGlobalEvent = handler.ref.current;
  if (componentWithGlobalEvent) {
    const eventCallback = componentWithGlobalEvent[eventFunctionName];
    eventCallback.call(componentWithGlobalEvent, event);
  }
});
const withGlobalEvent = function () {
  for (var _len = arguments.length, eventTypes = new Array(_len), _key = 0; _key < _len; _key++) {
    eventTypes[_key] = arguments[_key];
  }
  return WrappedComponent => class GlobalEvent extends React.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "ref", React.createRef());
    }
    componentDidMount() {
      eventTypes.forEach(eventType => {
        const globalState = globalEventState[eventType] || this._setupEventHandler(eventType);
        globalState.components.push(this);
      });
    }
    componentWillUnmount() {
      eventTypes.forEach(eventType => {
        const globalState = globalEventState[eventType];
        const index = globalState.components.indexOf(this);
        globalState.components = [...globalState.components.slice(0, index), ...globalState.components.slice(index + 1)];
        if (!globalState.components.length) {
          window.removeEventListener(eventType, globalState.eventHandler, {
            passive: false
          });
          globalEventState[eventType] = null;
        }
      });
    }
    _setupEventHandler(eventType) {
      const eventHandler = globalEventHandler(eventType);
      globalEventState[eventType] = {
        eventHandler,
        components: []
      };
      window.addEventListener(eventType, eventHandler, {
        passive: false
      });
      return globalEventState[eventType];
    }
    render() {
      return React.createElement(WrappedComponent, _extends({}, this.props, {
        ref: this.ref
      }));
    }
  };
};
svs.utils.withGlobalEvent = withGlobalEvent;

 })(window);